<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(first_name)="{ item }">
            <span>{{ stringName(item) }} </span>
          </template>
          <template v-slot:cell(active)="{ item }">
            <span :class="item.active ? 'text-success' : 'text-error'">{{
              item.active ? "Active" : "Inactive"
            }}</span>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <router-link :to="'manageStaff/detail/' + item.id">
                <b-button variant="link" class="text-warning px-1 py-0">
                  <font-awesome-icon icon="pencil-alt" title="Edit" />
                </b-button>
              </router-link>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      :pageOptions="pageOptions"
      @handleChangeTake="handleChangeTake"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },

  methods: {
    stringName(item) {
      const first_name = item.first_name || "";
      const last_name = item.last_name || "";

      return first_name + " " + last_name;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.show1 = 1;
      this.filter.take = value;
      this.toShow = value;
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.filter.activity_type = [];
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    chkBirthday(birthday) {
        let birthDate = birthday ? birthday.search("00:00:00") : -1;
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
</style>
